import React, { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import { RichText } from 'prismic-reactjs';
import { client } from '../prismic-configuration';
import { AuthForms, LoginForm } from '../components/Molecules/Auth';
import { AuthContext } from '../context/AuthContext';
import { SEOInfo } from '../helpers/seo';

import withoutAuth from '../HOC/withoutAuth';

const Login = ({ content }) => {
    const router = useRouter();
    const { initializeAuth, authenticate, message, user, loading } = useContext(AuthContext);

    useEffect(() => {
        initializeAuth();
    }, []);

    return (
        <>
            <NextSeo
                title="Otellu - Login to your account"
                description={SEOInfo.description}
                openGraph={{
                    url: SEOInfo.url,
                    title: SEOInfo.title,
                    description: SEOInfo.description,
                    images: [
                        {
                            url: SEOInfo.image,
                        },
                    ],
                    site_name: SEOInfo.siteName,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <div className="h-screen flex">
                <div className="flex flex-col justify-center items-center w-full sm:w-1/2 p-8 gap-12">
                    <div className="flex">
                        <Link href={`/`}>
                            <img className="w-16 h-16" src="/assets/logo/otellu-icon.svg" />
                        </Link>
                    </div>
                    <div className="w-96 min-w-full md:min-w-0 px-4">
                        <AuthForms onFinish={() => router.push('/')} hideRedirect={true} switchToLogin={true} />
                        <p
                            onClick={() => router.push('signup')}
                            data-cy="link-to-register"
                            className="text-sm text-gray-500 underline text-center cursor-pointer"
                        >
                            Do not have an account? Sign up
                        </p>
                    </div>
                </div>
                <div className="hidden sm:flex flex-col justify-between bg-gray-100 h-full w-1/2 bg-cover">
                    <div></div>
                    <div className="flex flex-col items-center">
                        <h2 className="text-4xl text-center text-gray-900">Make your dream work</h2>
                        <p className="text-center text-gray-700 mt-2 opacity-75">Nice to see you again!</p>
                    </div>
                    <div>
                        <img className="ml-auto" src="/assets/illustrations/isometric-icons.webp" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
